import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
import queryString from 'query-string';
export const getAllArticles = createAsyncThunk('article/getAllArticles', async ({ search, limit, page, archived, }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search ? { limit, page, archived } : { search, limit, page, archived });
        const response = await apiKey.get(`/articles?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const createArticle = createAsyncThunk('articles/createArticle', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post(`/articles`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateArticle = createAsyncThunk('articles/updateArticle', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/articles/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getArticleById = createAsyncThunk('articles/getArticleById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/articles/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const deleteArticle = createAsyncThunk('articles/deleteArticle', async ({ id }, ThunkApi) => {
    try {
        return await apiKey.delete(`/articles/${id}`);
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
